export * from './admin.service';
import { AdminService } from './admin.service';
export * from './auth.service';
import { AuthService } from './auth.service';
export * from './default.service';
import { DefaultService } from './default.service';
export * from './solarHuaweiAlarm.service';
import { SolarHuaweiAlarmService } from './solarHuaweiAlarm.service';
export * from './solarHuaweiBasic.service';
import { SolarHuaweiBasicService } from './solarHuaweiBasic.service';
export * from './solarHuaweiMonitoring.service';
import { SolarHuaweiMonitoringService } from './solarHuaweiMonitoring.service';
export * from './solarHuaweiReport.service';
import { SolarHuaweiReportService } from './solarHuaweiReport.service';
export * from './user.service';
import { UserService } from './user.service';
export const APIS = [AdminService, AuthService, DefaultService, SolarHuaweiAlarmService, SolarHuaweiBasicService, SolarHuaweiMonitoringService, SolarHuaweiReportService, UserService];
