/**
 * @scada/api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.18.5
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface AlarmItemResponse { 
    stationCode: string;
    alarmCause: string;
    alarmId: number;
    alarmName: string;
    alarmType: number;
    alarmTypeDescription?: string;
    causeId: number;
    devName: string;
    devTypeId: number;
    devTypeIdDescription?: string;
    esnCode: string;
    lev: number;
    levDescription?: string;
    raiseTime: string;
    repairSuggestion: string;
    stationName: string;
    status: number;
    statusDescription?: string;
}

