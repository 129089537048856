import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { BehaviorSubject } from 'rxjs';
import { AuthTokenResponse } from 'swagger-lib';

@Injectable({
  providedIn: 'root'
})
export class LocalAuthService {
  userInfo = new BehaviorSubject<null>(null);
  jwtHelperService = new JwtHelperService();

  constructor(private router: Router) { }

  setSession(res: AuthTokenResponse): void {
    if (!this.jwtHelperService.isTokenExpired(res.accessToken)) {
      localStorage.setItem('access_token', res.accessToken);
      localStorage.setItem('refresh_token', res.refreshToken);
      this.userInfo.next(this.jwtHelperService.decodeToken(res.accessToken));
    } else {
      this.userInfo.next(null);
    }
  }

  getTokenExpDate(): Date | null {
    if (!this.accessToken)
      return null;
    return this.jwtHelperService.getTokenExpirationDate(this.accessToken);
  }

  isAccessTokenExpired(): boolean {
    if (!this.accessToken)
      return true;
    return this.jwtHelperService.isTokenExpired(this.accessToken);
  }

  isRefreshTokenExpired(): boolean {
    if (!this.refreshToken)
      return true;
    return this.jwtHelperService.isTokenExpired(this.refreshToken);
  }

  get accessToken(): string | null {
    const token = localStorage.getItem('access_token');
    if (!token)
      return null;
    return token;
  }

  get refreshToken(): string | null {
    const token = localStorage.getItem('refresh_token');
    if (!token)
      return null;
    return token;
  }

  get actionToken(): string | null {
    const token = localStorage.getItem('action_token');
    if (!token)
      return null;
    return token;
  }

  get userRole(): string | null {
    if (this.accessToken) {
      const userRole = this.jwtHelperService.decodeToken(this.accessToken).rle;
      if (userRole) {
        return userRole;
      }
    }
    return null;
  }

  logout(): void {
    this.userInfo.next(null);
    localStorage.clear();
    this.router.navigate(['/login']);
  }
}
