import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable, throwError } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { AuthTokenResponse } from 'swagger-lib';
import { AuthService } from 'swagger-lib/api/auth.service';
import { LocalAuthService } from '../services/auth.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(
    private localAuthService: LocalAuthService,
    private authService: AuthService,
    private toastr: MatSnackBar
  ) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (request.url.includes('auth/refresh')) {
      request = this.addAccessToken(request, true);
    } else if (request.url.includes('finish-registration') || request.url.includes('forgot-password-submit') || request.url.includes('2fa')) {
      request = this.addActionToken(request);
    } else {
      request = this.addAccessToken(request, false);
    }

    return next.handle(request).pipe(
      tap((event: HttpEvent<unknown>) => {
        if (event instanceof HttpResponse) {
        }
      }), catchError((error: HttpErrorResponse) => {
        if (error.status === 401 && !error.url?.includes('2fa')) {
          if (request.url.includes('auth/refresh')) {
            this.localAuthService.logout();
          } else {
            if (this.localAuthService.isAccessTokenExpired() && !this.localAuthService.isRefreshTokenExpired() && this.localAuthService.refreshToken) {
              return this.authService.authRefreshPost().pipe(switchMap((res: AuthTokenResponse) => {
                this.localAuthService.setSession(res);
                return next.handle(this.addAccessToken(request, false));
              }));
            } else {
              this.localAuthService.logout();
            }
          }
        }

        if (error.error.error === 'Internal Server Error') {
          this.toastr.open('Ooops, something went wrong. Please try again later', '', {
            duration: 3000,
            panelClass: ['error-snackbar']
          });
        } else if (error.error && error.error.message) {
          const errorMessage = error.error.message.charAt(0).toUpperCase() + error.error.message.slice(1);
          this.toastr.open(errorMessage, '', {
            duration: 5000,
            panelClass: ['error-snackbar']
          });
        }
        return throwError(() => error);
      }));
  }

  addAccessToken(request: HttpRequest<unknown>, isRefresh: boolean) {
    const token = this.localAuthService.accessToken;

    if (token && !isRefresh) {
      return request.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`
        }
      });
    } else if (isRefresh) {
      return request.clone({
        setHeaders: {
          Authorization: `Bearer ${this.localAuthService.refreshToken}`
        }
      });
    }
    return request;
  }

  addActionToken(request: HttpRequest<unknown>) {
    const token = this.localAuthService.actionToken;

    if (token) {
      return request.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`
        }
      });
    }
    return request;
  }

}
