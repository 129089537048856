/**
 * @scada/api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.18.5
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface PlantDataItemResponse { 
    stationCode: string;
    totalIncome: number;
    totalPower: number;
    dayPower: number;
    dayIncome: number;
    realHealthState: number;
    realHealthStateDescription?: string;
    monthPower: number;
}

