/**
 * @scada/api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.18.5
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ChartRequestUnitAnyOf2 } from './chartRequestUnitAnyOf2';
import { ChartRequestUnitAnyOf3 } from './chartRequestUnitAnyOf3';
import { ChartRequestUnitAnyOf1 } from './chartRequestUnitAnyOf1';
import { ChartRequestUnitAnyOf } from './chartRequestUnitAnyOf';


export interface ChartRequestUnit { 
}

