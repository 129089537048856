import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { LocalAuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard {

  constructor(
    private authService: LocalAuthService,
    private router: Router
  ) { }

  canActivate(): boolean {
    if (!this.authService.accessToken) {
      this.router.navigate(['/']);
      return false;
    }
    return true;
  }
}
